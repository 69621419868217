@import './colors.css';

body {
  margin: 0;
  font-family: 'Trebuchet MS', roboto, ubuntu, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --black: rgba(19, 23, 34);
  --price-green: rgb(0, 169, 127);
  --price-red: rgb(255, 23, 62);
  --price-gray: rgb(74, 74, 74);
  --border-gray: rgb(237, 240, 242);
  --subheader-gray: #425a70;

  /* search bar */
  --elevated-bg-0: rgb(22, 25, 28);
  --elevated-bg: rgba(46, 50, 55);
  --elevated-bg-2: rgb(59, 64, 70);
  --elevated-bg-3: rgb(75, 81, 89);
  --elevated-bg-4: rgb(91, 97, 105);

  --black-1: #16191c;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
  display: flex;
}

div[id^='evergreen-tooltip'] {
  background-color: black;
}

button[class^='css-']:not([disabled]):not([data-disabled]):focus {
  box-shadow: none;
}

.react-draggable,
.cursor {
  cursor: move;
}
.no-cursor {
  cursor: auto;
}
.cursor-y {
  cursor: ns-resize;
}
.cursor-x {
  cursor: ew-resize;
}

.react-draggable strong {
  background: #ddd;
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.crypto-table-row:hover {
  background-color: #f7f8fa !important;
  cursor: pointer;
}

.screen-inner {
  max-width: 400px;
  width: 100%;
}

.text-input-field {
  display: inline-block;
  width: 100%;
}

.text-input-field:hover .contenteditable-reset {
  opacity: 1;
}

.text-input-field-entry {
  position: relative;
}

.contenteditable-placeholder {
  transition: all 0.5s ease;
  top: 0;
}

.contenteditable-placeholder.has-content,
.contenteditable-span:focus + .contenteditable-placeholder {
  top: -20px;
}

.contenteditable-span {
  position: relative;
  min-height: 1rem;
}

.contenteditable-span:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 2px;
  content: '';
  display: block;
  background-color: var(--oc-gray-2);
}

.contenteditable-span:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 2px;
  content: '';
  display: block;
  background-color: var(--oc-gray-7);
  z-index: 2;
  transition: transform 250ms ease;
  transform: scaleX(0);
}

.text-input-field:hover .contenteditable-span:before {
  transform: scaleX(1);
}

.contenteditable-reset {
  position: absolute;
  right: -20px;
}

.close-button svg {
  fill: white !important;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.close-button:hover svg {
  opacity: 1;
}

.outline-button {
  border: 2px solid RGBA(0, 151, 239, 1);
  color: RGBA(0, 151, 239, 1);
  background-color: transparent;
  transition: all 0.3s ease;
  font-variant: all-petite-caps;
  line-height: 1.25;
}

button:focus {
  outline: none;
}

button[disabled],
button[disabled]:hover {
  cursor: not-allowed;
}

.outline-button:hover {
  background-color: rgba(0, 151, 239, 1);
  color: white;
  cursor: pointer;
}

.screen {
  opacity: 0;
  transition: all 0.2s ease;
  transform: scale(1.2);
  overflow: hidden;
  background-color: rgba(28, 32, 47, 1);
  z-index: 20000;
}

.screen.is-open {
  opacity: 1;
  transform: scale(1);
  overflow: auto;
}

.screen.is-open:not(:focus-within) {
  background-color: rgba(28, 32, 48, 1);
  transition: background-color 0.01s;
}

.loading svg circle {
  stroke: white;
}

.broker-item {
  transition: all 0.3s ease;
  background-color: white;
}

.broker-item:hover {
  background-color: rgba(210, 210, 210, 0.3);
  cursor: pointer;
}

.symbol-search-input {
  box-shadow: none;
  background-color: var(--elevated-bg) !important;
  color: white !important;
}

.symbol-search-input::placeholder {
  color: white !important;
  opacity: 0.3;
}

.symbol-search-item {
  background-color: var(--elevated-bg-3) !important;
  color: white;
}

.symbol-search-item > div:first-child {
  display: none;
}

.symbol-search-item > div {
  padding: 0 16px;
  border-color: var(--elevated-bg-4);
}

.symbol-search-item > div span {
  color: white !important;
}

.input-addon {
  background: var(--elevated-bg-2) !important;
  color: white !important;
}

.input-addon:hover {
  background: var(--elevated-bg-3) !important;
}

.input-addon:disabled {
  color: var(--text-gray) !important;
  opacity: 0.5;
}

.input-addon:focus {
  outline: none;
  box-shadow: none !important;
}

.accounts-list {
  max-width: 100%;
}

@media screen and (max-width: 700px) {
  .accounts-list {
    max-width: 700px;
  }
}

.order-book-row:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

[evergreen-portal-container] [role='dialog'] {
  z-index: 10000 !important;
}

.ticker-search-item:hover {
  background-color: #f7f8fa !important;
}

.ticker-search-item.highlighted,
.ticker-search-item.selected {
  background-color: #f7f8fa !important;
}
