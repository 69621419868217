@import 'fonts.scss';

body {
  --color-green: #54ea0a;
  --color-red: #f5222d;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#root {
  height: 100%;
  display: flex;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.coin-icon {
  flex: none;
}

.ant-layout-sider-children {
  li.ant-menu-item {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.ant-menu-inline-collapsed {
  li.ant-menu-item {
    justify-content: center;
  }
  .coin-icon {
    margin: 0 !important;
  }
  .coin-label {
    display: none;
  }
}

.header {
  display: flex;
}

.header-logo {
  color: white;
  font-weight: bold;
  text-decoration: none;
  margin-right: 25px;
}

.header-user-menu {
  margin-left: auto;
  color: white;
}

.login-form-forgot {
  float: right;
}
.login-form-button,
.challenge-form-button {
  width: 100%;
}

.ant-table-row {
  cursor: pointer;
}

.ant-layout {
  background-color: white;
}
