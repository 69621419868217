@font-face {
  font-family: 'DINNext';
  src: local('DINNext'), url('./fonts/DINNextLTPro-Light.woff2') format('woff2');
  font-weight: 300;
}
@font-face {
  font-family: 'DINNext';
  src: local('DINNext'),
    url('./fonts/DINNextLTPro-Regular.woff2') format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'DINNext';
  src: local('DINNext'),
    url('./fonts/DINNextLTPro-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'DINNext';
  src: local('DINNext'), url('./fonts/DINNextLTPro-Bold.woff2') format('woff2');
  font-weight: 700;
}
